import {
  QueryKey,
  QueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useAuthentication, useSelectedClient } from './hooks/authentication';
import { logger } from '../utils';
import { useEffect, useRef } from 'react';

export interface QueryKeyOptions {
  includeParams: boolean;
}

// Params type has to have any because URLSearchParams types are poorly defined and don't include arrays
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildUseGetHook<Resource, Params extends Record<string, any>>(
  resourceName: string,
  url: string
): (
  resourceId: string,
  params?: Params,
  queryOptions?: UseQueryOptions<unknown, unknown, Resource, QueryKey>,
  queryKeyOptions?: QueryKeyOptions
) => UseQueryResult<Resource> {
  return function useGet(
    resourceId: string,
    params?: Params,
    queryOptions?: QueryOptions,
    queryKeyOptions?: QueryKeyOptions
  ) {
    const { token } = useAuthentication() as { token: string };
    const { selectedClient } = useSelectedClient() as {
      selectedClient: string;
    };

    const tokenRef = useRef(token);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);

    const queryKey: unknown[] = [resourceName, resourceId];

    if (queryKeyOptions?.includeParams === true) {
      queryKey.push(params);
    } else {
      queryKey.push(undefined); // used to keep indexes consistent
    }

    return useQuery({
      queryKey: queryKey,
      queryFn: () =>
        fetchResource(tokenRef.current, selectedClient, resourceId, params),
      ...queryOptions
    });
  };

  async function fetchResource(
    token: string,
    clientId: string,
    resourceId: string,
    params?: Params
  ): Promise<Resource> {
    const query = params ? new URLSearchParams(params).toString() : '';

    const response = await fetch(`${url}/${resourceId}?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'x-ebiai-account-id': clientId
      }
    });

    if (!response.ok) {
      logger('Request failed:', JSON.stringify(await response.json(), null, 2));
      throw new Error(`Could not get ${resourceName} ${resourceId}`);
    }

    return (await response.json()) as Resource;
  }
}

export function buildUseCreateHook<Resource, Params>(
  resourceName: string,
  url: string,
  invalidate: (data: Resource, params: Params) => QueryKey[] = () => []
): () => UseMutationResult<Resource, unknown, Params> {
  return function useCreate() {
    const queryClient = useQueryClient();
    const { token } = useAuthentication() as { token: string };
    const { selectedClient } = useSelectedClient() as {
      selectedClient: string;
    };

    const tokenRef = useRef(token);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);

    return useMutation({
      mutationFn: (params: Params) =>
        postResource(tokenRef.current, selectedClient, params),
      onSuccess: async (data, params) => {
        for (const queryKey of invalidate(data, params)) {
          await queryClient.invalidateQueries({ queryKey });
        }
        await queryClient.invalidateQueries({ queryKey: [resourceName] });
      }
    });
  };

  async function postResource(
    token: string,
    clientId: string,
    params: Params
  ): Promise<Resource> {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-ebiai-account-id': clientId
      },
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      logger('Request failed:', JSON.stringify(await response.json(), null, 2));
      throw new Error(`Could not post new ${resourceName}`);
    }
    const data = (await response.json()) as Resource;
    return { ...data, status: response.status } as Resource;
  }
}

export function buildUsePatchHook<Resource, Params>(
  resourceName: string,
  url: string
): (props?: {
  onSuccess?: () => void;
  onError?: () => void;
}) => UseMutationResult<Resource, unknown, { id: string; params: Params }> {
  return function usePatch(props?: {
    onSuccess?: () => void;
    onError?: () => void;
  }) {
    const queryClient = useQueryClient();
    const { token } = useAuthentication() as { token: string };
    const { selectedClient } = useSelectedClient() as {
      selectedClient: string;
    };

    const tokenRef = useRef(token);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);

    return useMutation({
      mutationFn: (args: { id: string; params: Params }) =>
        patchResource(tokenRef.current, selectedClient, args),
      onSuccess: async data => {
        const resourceId = (data as unknown as { _id: string })?._id;
        const queryKey = [resourceName];

        if (resourceId) {
          queryKey.push(resourceId);
        }

        await queryClient.invalidateQueries({ queryKey });
        props?.onSuccess?.();
      },
      onError: () => {
        props?.onError?.();
      }
    });
  };

  async function patchResource(
    token: string,
    clientId: string,
    { id, params }: { id: string; params: Params }
  ): Promise<Resource> {
    const response = await fetch(`${url}/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-ebiai-account-id': clientId
      },
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      logger('Request failed:', JSON.stringify(await response.json(), null, 2));
      throw new Error(`Could not patch ${resourceName} ${id}`);
    }

    return (await response.json()) as Resource;
  }
}

const PAGE_SIZE = 10;

interface Page<Resource> {
  count: number;
  items: Resource[];
}

interface ParamsList {
  limit?: number;
  page?: number | string;
}

export function buildUseListHook<Resource, Params extends ParamsList>(
  resourceName: string,
  url: string
): (
  params: Params,
  queryOptions?: UseInfiniteQueryOptions<Page<Resource>>,
  queryKeyOptions?: QueryKeyOptions
) => UseInfiniteQueryResult<Page<Resource>> {
  return function useList(
    params: Params,
    queryOptions?: UseInfiniteQueryOptions<Page<Resource>>,
    queryKeyOptions?: QueryKeyOptions
  ) {
    const { token } = useAuthentication() as { token: string };
    const { selectedClient } = useSelectedClient() as {
      selectedClient: string;
    };

    const tokenRef = useRef(token);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);

    const { page } = params;

    const queryKey: unknown[] = [resourceName];

    if (queryKeyOptions?.includeParams === true) {
      queryKey.push(params);
    } else {
      queryKey.push(undefined); // used to keep indexes consistent
    }

    return useInfiniteQuery({
      queryKey: queryKey,
      queryFn: ({ pageParam = page ?? 1 }) => {
        return fetchPage(
          tokenRef.current,
          selectedClient,
          params,
          pageParam as number
        );
      },
      getNextPageParam: (
        lastPage: Page<Resource>,
        allPages: Page<Resource>[]
      ) => {
        if (lastPage.count > allPages.length * (params.limit ?? PAGE_SIZE)) {
          return allPages.length + 1;
        }

        return undefined;
      },
      ...queryOptions
    });
  };

  async function fetchPage(
    token: string,
    clientId: string,
    params: Params | { page?: number },
    pageParam: number
  ) {
    const { limit } = params as {
      limit: number | null;
    };

    const query = new URLSearchParams({
      ...(params as Record<string, string>),
      skip: ((pageParam - 1) * (limit ? limit : PAGE_SIZE)).toString(),
      limit:
        limit !== null && !isNaN(limit)
          ? limit.toString()
          : PAGE_SIZE.toString()
    }).toString();

    const response = await fetch(`${url}?${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'x-ebiai-account-id': clientId
      }
    });

    if (!response.ok) {
      logger('Request failed:', JSON.stringify(await response.json(), null, 2));
      throw new Error(`Could not get next ${resourceName} page`);
    }

    return (await response.json()) as Page<Resource>;
  }
}

export function buildUseDeleteHook<Resource, Params = undefined>(
  resourceName: string,
  url: string
): (props?: {
  onSuccess?: () => void;
  onError?: () => void;
}) => UseMutationResult<Resource, unknown, { id: string; params?: Params }> {
  return function useDelete(props?: {
    onSuccess?: () => void;
    onError?: () => void;
  }) {
    const queryClient = useQueryClient();
    const { token } = useAuthentication() as { token: string };
    const { selectedClient } = useSelectedClient() as {
      selectedClient: string;
    };

    const tokenRef = useRef(token);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);

    return useMutation({
      mutationFn: (args: { id: string; params?: Params }) =>
        deleteResource(tokenRef.current, selectedClient, args),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [resourceName] });
        props?.onSuccess?.();
      },
      onError: () => {
        props?.onError?.();
      }
    });
  };

  async function deleteResource(
    token: string,
    clientId: string,
    { id, params }: { id: string; params?: Params }
  ): Promise<Resource> {
    const response = await fetch(`${url}/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'x-ebiai-account-id': clientId
      },
      ...(params ? { body: JSON.stringify(params) } : null)
    });

    if (!response.ok) {
      logger('Request failed:', JSON.stringify(await response.json(), null, 2));
      throw new Error(`Could not delete ${resourceName} ${id}`);
    }

    // To handle empty response and avoid unexpected json error
    const responseText = await response.text();
    const json = safeParseJSON(responseText) as Resource;

    return json;
  }
}

function safeParseJSON(json: string): unknown {
  try {
    return JSON.parse(json || '');
  } catch (err: unknown) {
    const error = err as Error;
    logger('caught error parsing json', error);
  }
  return {};
}

export function buildUsePutHook<Resource, Params>(
  resourceName: string
): () => UseMutationResult<
  Resource | null,
  unknown,
  { url: string; params: Params }
> {
  return function usePut() {
    const queryClient = useQueryClient();
    const { token } = useAuthentication() as { token: string };

    const tokenRef = useRef(token);

    useEffect(() => {
      tokenRef.current = token;
    }, [token]);

    return useMutation({
      mutationFn: (args: { url: string; params: Params }) =>
        putResource(tokenRef.current, args),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [resourceName] });
      }
    });
  };

  async function putResource(
    token: string,
    { url, params }: { url: string; params: Params }
  ): Promise<Resource | null> {
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      logger('Request failed:', JSON.stringify(await response.json(), null, 2));
      throw new Error(`Could not put ${resourceName}`);
    }

    // To handle empty response and avoid unexpected json error
    const responseText = await response.text();
    const json = safeParseJSON(responseText) as Resource | null;

    return json;
  }
}
