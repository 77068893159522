import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import pageRedirect from './assets/page-redirect.svg';
import { ReactComponent as Logo } from './assets/header-logo.svg';

import styled from 'styled-components';

const OAuthRedirect: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentParams = window.location.search;
    const encodedState = queryParams.get('state');

    if (encodedState) {
      const decodedState = atob(encodedState);
      const [, connectorId, type] = decodedState.split('|');

      navigate(`/connectors/${connectorId}/oauth${currentParams}`, {
        state: {
          oauthType: type
        }
      });
    }
  }, [location.search, navigate]);

  return (
    <RedirectContainer>
      <HeaderWrapper>
        <Logo />
      </HeaderWrapper>
      <RedirectWrapper>
        <RedirectImage src={pageRedirect} alt="" />
        <Heading>Redirecting</Heading>
        <SubHeading>
          Please wait a while. You&apos;re being redirected
        </SubHeading>
      </RedirectWrapper>
    </RedirectContainer>
  );
};

export default OAuthRedirect;

const RedirectContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  background-color: #e5f3f2;
  height: 100vh;
`;

const HeaderWrapper = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 2;
  background-color: #002140;
`;

const RedirectWrapper = styled.div`
  margin: 110px 90px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px;
  border-radius: 15px;
`;

const RedirectImage = styled.img`
  margin-bottom: 30px;
`;

const Heading = styled.h1`
  font-size: 42px;
  color: #06233d;
  margin-bottom: 20px;
`;

const SubHeading = styled.p`
  font-size: 22px;
  color: #06233d;
`;
